import Image from 'next/image';
import React from 'react';

type ProductBadgeProps = {
  type?: 'special-price' | 'new' | 'best-seller' | '1get1';
  imageName?: string;
};

function ProductBadge({
  type = 'special-price',
  imageName,
}: ProductBadgeProps) {
  const badge = {
    'special-price': {
      src: '/badge/special-price.png',
      alt: 'sale',
    },
    new: {
      src: '/badge/new-arrival.png',
      alt: 'new',
    },
    'best-seller': {
      src: '/badge/bestseller.png',
      alt: 'best-seller',
    },
    '1get1': {
      src: '/badge/1get1.png',
      alt: '1get1',
    },
  };

  return (
    <div>
      {imageName ? (
        <Image
          src={`${process.env.NEXT_PUBLIC_FILE_URL}/${imageName}`}
          alt={imageName}
          width={50}
          height={50}
        />
      ) : (
        <Image
          src={badge[type].src}
          alt={badge[type].alt}
          width={50}
          height={50}
        />
      )}
    </div>
  );
}

export default ProductBadge;
