import { Box, Button, useMediaQuery } from '@chakra-ui/react';
import useProduct from 'hooks/useProduct';
import useProducts from 'hooks/useProducts';
import { IProduct } from 'interfaces/product.interface';
import Image from 'next/image';
import { useState } from 'react';
import { EqualHeight } from 'react-equal-height/clean';
import { Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductItem from './ProductItem';

type ProductListSwiperProps = {
  products: IProduct[];
  navigation?: boolean;
  breakpoints: any;
};

function ProductListSwiper({
  products,
  navigation = true,
  breakpoints,
}: ProductListSwiperProps) {
  const [controlledSwiper, setControlledSwiper] = useState<any>();

  const [isReachEnded, setIsReachEnded] = useState<boolean>(false);

  const [currentSliderIndex, setCurrentSliderIndex] = useState<number>(0);

  return (
    <>
      <Box position="relative" mx={-1}>
        <EqualHeight>
          <Swiper
            modules={[Controller, Navigation]}
            onSwiper={setControlledSwiper}
            onReachEnd={() => setIsReachEnded(true)}
            onReachBeginning={() => setIsReachEnded(false)}
            onSlideChange={(swiper) => {
              setCurrentSliderIndex(swiper.activeIndex);
            }}
            loop={false}
            breakpoints={breakpoints}
          >
            {products.map((product, index) => (
              <SwiperSlide
                key={index}
                style={{
                  height: 'auto',
                }}
              >
                <Box p={1} height="100%">
                  <ProductItem product={product}></ProductItem>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </EqualHeight>
        {controlledSwiper && navigation ? (
          <>
            {!controlledSwiper.isBeginning ? (
              <Button
                display={{
                  base: 'none',
                  lg: 'inline-flex',
                }}
                position="absolute"
                left={-12}
                top="50%"
                transform="translateY(-50%)"
                fontSize="4xl"
                colorScheme="primary"
                variant="unstyled"
                bg="gray.100"
                width="32px"
                minW="auto"
                height="32px"
                borderRadius="full"
                onClick={() => controlledSwiper.slidePrev()}
                _focus={{
                  boxShadow: 'none',
                  bg: '#dcdcdc',
                }}
                _active={{
                  bg: '#dcdcdc',
                }}
              >
                <Image
                  src="/image-icon/arrow-prev.png"
                  width={16}
                  height={18}
                  alt="prev"
                ></Image>
              </Button>
            ) : null}
            {!controlledSwiper.isEnd ? (
              <Button
                display={{
                  base: 'none',
                  lg: 'inline-flex',
                }}
                position="absolute"
                right={-12}
                top="50%"
                transform="translateY(-50%)"
                fontSize="4xl"
                colorScheme="primary"
                variant="unstyled"
                bg="gray.100"
                width="32px"
                minW="auto"
                height="32px"
                borderRadius="full"
                onClick={() => controlledSwiper.slideNext()}
                _focus={{
                  boxShadow: 'none',
                  bg: '#dcdcdc',
                }}
                _active={{
                  bg: '#dcdcdc',
                }}
              >
                <Image
                  src="/image-icon/arrow-next.png"
                  width={16}
                  height={18}
                  alt="next"
                ></Image>
              </Button>
            ) : null}
          </>
        ) : null}
      </Box>
    </>
  );
}

export default ProductListSwiper;
