import { Box } from '@chakra-ui/react';
import { IHomeSingleBanner } from 'interfaces/home.interface';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { CSSProperties } from 'react';
import NextLink from 'next/link';

type HomeSingleBannerProps = {
  banner: IHomeSingleBanner;
  desktopWidth: number;
  desktopHeight: number;
  mobileWidth: number;
  mobileHeight: number;
  desktopStyle?: CSSProperties;
  mobileStyle?: CSSProperties;
};

function HomeSingleBanner({
  banner,
  desktopWidth,
  desktopHeight,
  mobileWidth,
  mobileHeight,
  desktopStyle,
  mobileStyle,
}: HomeSingleBannerProps) {
  // use i18n
  const { t } = useTranslation(['home']);

  return (
    <>
      {banner.mediaDesktop && (
        <Box>
          <NextLink href={banner.link ?? ''} target={banner.target ?? '_self'}>
            <Image
              src={
                banner.mediaDesktop
                  ? `${process.env.NEXT_PUBLIC_FILE_URL}/${banner.mediaDesktop.name}`
                  : '/image-icon/empty-image.png'
              }
              alt=""
              width={desktopWidth}
              height={desktopHeight}
              style={
                desktopStyle
                  ? desktopStyle
                  : {
                      width: '100%',
                      height: 'auto',
                    }
              }
            ></Image>
          </NextLink>
        </Box>
      )}
    </>
  );
}

export default HomeSingleBanner;
