import { Box, ResponsiveValue, SimpleGrid } from '@chakra-ui/react';
import { IHomeBanner } from 'interfaces/home.interface';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import NextLink from 'next/link';

type HomeSectionBannerProps = {
  bannerItems: IHomeBanner[];
  columns: ResponsiveValue<number>;
  spacing: ResponsiveValue<number>;
  linkTarget?: string;
};

function HomeSectionBanner({
  bannerItems,
  columns,
  spacing,
  linkTarget,
}: HomeSectionBannerProps) {
  // use i18n
  const { t } = useTranslation(['home']);

  return (
    <SimpleGrid columns={columns} spacing={spacing}>
      {bannerItems.map((bannerItem, index) => (
        <Box key={index}>
          <NextLink
            href={bannerItem.link ?? ''}
            target={linkTarget ? linkTarget : bannerItem.target ?? '_self'}
          >
            <Image
              src={
                `${process.env.NEXT_PUBLIC_FILE_URL}/${bannerItem.media.name}` ??
                'image-icon/empty-image.png'
              }
              alt=""
              width={300}
              height={200}
              quality={100}
            ></Image>
          </NextLink>
        </Box>
      ))}
    </SimpleGrid>
  );
}

export default HomeSectionBanner;

