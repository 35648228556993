import {
  AspectRatio,
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
} from '@chakra-ui/react';
import UtilPriceFormatter from 'components/utils/UtilPriceFormatter';
import useProduct from 'hooks/useProduct';
import useProductItem from 'hooks/useProductItem';
import { IProduct } from 'interfaces/product.interface';
import Image from 'next/image';
import NextLink from 'next/link';
import { EqualHeightElement } from 'react-equal-height/clean';
import ProductBadge from './ProductBadge';
import { useRouter } from 'next/router';

type ProductItemProps = {
  product: IProduct;
};

function ProductItem({ product }: ProductItemProps) {
  const router = useRouter();

  const { locale } = router;

  const { featuredImage, salePrice, savingPrice, regularPrice } =
    useProductItem({
      product,
    });

  return (
    <Box
      height="100%"
      _hover={{
        boxShadow: '0 0 6px #fdb869',
        transition: 'all 0.2s ease',
      }}
    >
      <NextLink
        href={`
              /products/${product?.permalink}
            `}
        passHref
        legacyBehavior
      >
        <Link height="100%" textDecoration="none !important">
          <Flex height="100%" flexDirection="column">
            <Box position="relative">
              {product.productBadge ? (
                <Center
                  position="absolute"
                  top="0"
                  left={3}
                  zIndex="docked"
                  px={1}
                  minH="44.1px"
                  borderBottomRadius="4px"
                >
                  <ProductBadge
                    imageName={product.productBadge.imageName ?? ''}
                  ></ProductBadge>
                </Center>
              ) : product.isNewArrival ? (
                <Center
                  position="absolute"
                  top="0"
                  left={3}
                  zIndex="docked"
                  px={1}
                  minH="44.1px"
                  borderBottomRadius="4px"
                >
                  <ProductBadge type="new"></ProductBadge>
                </Center>
              ) : product.isBestSeller ? (
                <Center
                  position="absolute"
                  top="0"
                  left={3}
                  zIndex="docked"
                  px={1}
                  minH="44.1px"
                  borderBottomRadius="4px"
                >
                  <ProductBadge type="best-seller"></ProductBadge>
                </Center>
              ) : product.isOnSale ? (
                <Center
                  position="absolute"
                  top="0"
                  left={3}
                  zIndex="docked"
                  px={1}
                  minH="44.1px"
                  borderBottomRadius="4px"
                >
                  <ProductBadge type="special-price"></ProductBadge>
                </Center>
              ) : null}
              <AspectRatio ratio={1}>
                <Image
                  src={`${featuredImage}`}
                  width={500}
                  height={500}
                  alt={product.name_en}
                ></Image>
              </AspectRatio>
            </Box>
            <Flex bg="#EADECE" py={2} px={3} flex={1} flexDirection="column">
              <EqualHeightElement name="title">
                <Heading
                  fontWeight="300"
                  textTransform="uppercase"
                  fontSize="14px"
                  as="h5"
                  noOfLines={3}
                  flex={1}
                >
                  {locale === 'th' ? product.name_th : product.name_en}
                </Heading>
              </EqualHeightElement>
              <Flex
                justifyContent="flex-end"
                borderTop="1px solid"
                borderColor="gray.400"
                mt={2}
                pt={1}
              >
                <Box>
                  <HStack
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={1}
                  >
                    {salePrice > 0 ? (
                      <>
                        <Text fontSize="xs" pt={1} as="s">
                          <UtilPriceFormatter
                            price={regularPrice}
                            format="0,0"
                          ></UtilPriceFormatter>
                        </Text>
                        <Text
                          color="primary.500"
                          fontSize="2xl"
                          lineHeight={1.05}
                        >
                          <UtilPriceFormatter
                            price={salePrice}
                            format="0,0"
                          ></UtilPriceFormatter>
                        </Text>
                      </>
                    ) : (
                      <>
                        {regularPrice > 0 ? (
                          <Text color="primary.500" fontSize="2xl">
                            <UtilPriceFormatter
                              price={regularPrice}
                              format="0,0"
                            ></UtilPriceFormatter>
                          </Text>
                        ) : (
                          <Text>N/A</Text>
                        )}
                      </>
                    )}
                  </HStack>
                  {savingPrice > 0 ? (
                    <HStack justifyContent="flex-end" mt={1.5}>
                      <Box
                        fontSize="xs"
                        textTransform="uppercase"
                        bg="#eb4123"
                        flex="0 0 auto"
                        px={2}
                        color="white"
                      >
                        Save{' '}
                        <UtilPriceFormatter
                          price={savingPrice}
                          format="0,0"
                        ></UtilPriceFormatter>
                      </Box>
                    </HStack>
                  ) : null}
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Link>
      </NextLink>
    </Box>
  );
}

export default ProductItem;
