import { Box, Button, Link } from '@chakra-ui/react';
import useProductCategories from 'hooks/useProductCategories';
import useProductMainCategories from 'hooks/useProductMainCategories';
import { IHomeFeatureCategory } from 'interfaces/home.interface';
import { IProductCategory } from 'interfaces/product-category';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

type HomeFeaturedCategoriesSectionProps = {
  featuredCategories: IHomeFeatureCategory[];
};

function HomeFeaturedCategoriesSection({
  featuredCategories,
}: HomeFeaturedCategoriesSectionProps) {
  const [controlledSwiper, setControlledSwiper] = useState<any>();

  const { productCategories } = useProductCategories();

  const { productMainCategories } = useProductMainCategories();

  // use i18n
  const { t } = useTranslation(['home']);

  // current category
  const [currentCategories, setCurrentCategories] = useState<
    IProductCategory[]
  >([]);

  useEffect(() => {
    // log productCategories
    console.log('productCategories', productCategories);

    // log featuredCategories
    console.log('featuredCategories', featuredCategories);

    if (productCategories.length > 0 && featuredCategories.length > 0) {
      // log featuredCategories
      console.log('featuredCategories', featuredCategories);

      // get categories by id
      const _currentCategories = productCategories.filter(
        (category) =>
          category.id ===
          featuredCategories.find((item) => item.category == category.id)
            ?.category
      );

      // log category
      console.log('_currentCategories', _currentCategories);

      setCurrentCategories(_currentCategories);
    }
  }, [productCategories, featuredCategories]);

  return (
    <Box
      px={{
        base: 10,
        md: 0,
      }}
      position="relative"
    >
      <Swiper
        onSwiper={setControlledSwiper}
        breakpoints={{
          320: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 5,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 15,
          },
        }}
      >
        {featuredCategories.map((category, index) => (
          <SwiperSlide key={index}>
            <NextLink
              href={`/product-categories/${
                productMainCategories.find(
                  (m) =>
                    m.id ===
                    currentCategories.find((c) => c.id === category.category)
                      ?.mainCategoryId
                )?.permalink
              }/${
                currentCategories.find((c) => c.id === category.category)
                  ?.permalink || ''
              }`}
              legacyBehavior
              passHref
            >
              <Link display="block">
                <Image
                  src={
                    category.media
                      ? `${process.env.NEXT_PUBLIC_FILE_URL}/${category.media.name}`
                      : '/image-icon/empty-image.png'
                  }
                  alt=""
                  width={225}
                  height={225}
                ></Image>
              </Link>
            </NextLink>
          </SwiperSlide>
        ))}
      </Swiper>
      {controlledSwiper ? (
        <>
          <Button
            display={{
              base: 'inline-flex',
              md: 'none',
            }}
            opacity={0.7}
            position="absolute"
            left={-1}
            top="50%"
            transform="translateY(-50%)"
            fontSize="4xl"
            colorScheme="primary"
            variant="unstyled"
            bg="gray.100"
            width="28px"
            minW="auto"
            height="28px"
            borderRadius="full"
            onClick={() => controlledSwiper.slidePrev()}
            _focus={{
              boxShadow: 'none',
              bg: '#dcdcdc',
            }}
            _active={{
              bg: '#dcdcdc',
            }}
          >
            <Image
              src="/image-icon/arrow-prev.png"
              width={12}
              height={14}
              alt="prev"
            ></Image>
          </Button>
          <Button
            display={{
              base: 'inline-flex',
              md: 'none',
            }}
            opacity={0.7}
            position="absolute"
            right={-1}
            top="50%"
            transform="translateY(-50%)"
            fontSize="4xl"
            colorScheme="primary"
            variant="unstyled"
            bg="gray.100"
            width="28px"
            minW="auto"
            height="28px"
            borderRadius="full"
            onClick={() => controlledSwiper.slideNext()}
            _focus={{
              boxShadow: 'none',
              bg: '#dcdcdc',
            }}
            _active={{
              bg: '#dcdcdc',
            }}
          >
            <Image
              src="/image-icon/arrow-next.png"
              width={12}
              height={14}
              alt="next"
            ></Image>
          </Button>
        </>
      ) : null}
    </Box>
  );
}

export default HomeFeaturedCategoriesSection;
