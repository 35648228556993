import { PageCreateDto, PageUpdateDto } from 'dtos/page.dto';
import { IPaginationResponse } from 'dtos/pagination.dto';
import { IQueryFindManyDto } from 'dtos/query.dto';
import { IPage } from 'interfaces/page.interface';
import _axiosAdmin from 'libs/axios-admin';
import queryString from 'query-string';

export class PageRepository {
  constructor() {}

  static findMany(query: IQueryFindManyDto) {
    return new Promise<IPaginationResponse<IPage>>(async (resolve, reject) => {
      try {
        const queryStringify = queryString.stringify({
          limit: query.limit,
          page: query.page,
          sort: query.sort,
          where: query.where,
          include: query.include,
        });

        const { data } = await _axiosAdmin.get<IPaginationResponse<IPage>>(
          `/v1/pages?${queryStringify}`
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Get one
   * @param id
   * @returns {IPage}
   */
  static findOne(id: number) {
    return new Promise<IPage>(async (resolve, reject) => {
      try {
        const { data } = await _axiosAdmin.get<IPage>(`/v1/pages/${id}`);
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Get one
   * @param id
   * @returns {IPage}
   */
  static findOneByPermalink(permalink: string) {
    return new Promise<IPage>(async (resolve, reject) => {
      try {
        const { data } = await _axiosAdmin.get<IPage>(
          `/v1/pages/permalink/${permalink}`
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Create one
   * @param body
   * @returns
   */
  static create(body: PageCreateDto) {
    return new Promise<IPage>(async (resolve, reject) => {
      try {
        const { data } = await _axiosAdmin.post<IPage>(`/v1/pages`, body);
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Update one
   * @param id
   * @param body
   * @returns
   */
  static update(id: number, body: PageUpdateDto) {
    return new Promise<IPage>(async (resolve, reject) => {
      try {
        const { data } = await _axiosAdmin.patch<IPage>(
          `/v1/pages/${id}`,
          body
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Delete one
   * @param id
   * @returns
   */
  static delete(id: number) {
    return new Promise<IPage>(async (resolve, reject) => {
      try {
        const { data } = await _axiosAdmin.delete(`/v1/pages/${id}`);
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Delete many
   * @param ids
   * @returns {ids}
   */
  static deleteMany(ids: number[]) {
    return new Promise<IPage>(async (resolve, reject) => {
      try {
        // convert ids to queryString
        const queryStringify = queryString.stringify({
          ids: ids.join(','),
        });
        const { data } = await _axiosAdmin.delete(
          `/v1/pages/many?${queryStringify}`
        );
        return resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
